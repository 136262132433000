<template>
  <div>
    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <vs-th>Action</vs-th>
        <vs-th sort-key="">Created At</vs-th>
        <vs-th sort-key="">File</vs-th>
        <vs-th sort-key="">Status</vs-th>
        <vs-th sort-key="">Message</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <vs-button
              @click="openModalDetail(tr)"
              color="primary"
              type="filled"
              size="small"
              >Detail</vs-button
            >
          </vs-td>
          <vs-td>{{
            convertUTCtoLocalDateTime(tr.created_at, "DD-MM-YYYY HH:mm:ss")
          }}</vs-td>
          <vs-td> <a href="#" v-on:click="downloadFileAwsS3(tr.file_path)">File</a> </vs-td>
          <vs-td> {{ tr.status }} </vs-td>
          <vs-td> {{ tr.remark }} </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />

    <!-- modal -->
    <vs-popup
      color="info"
      title="Detail Log"
      :active.sync="activeModalDetail"
      :buttonCancel="false"
    >
      <TableLine :parentID="activeParentID" ref="tableLine" />
    </vs-popup>
  </div>
</template>

<style>
.con-vs-popup .vs-popup {
  width: 100% !important;
}
</style>

<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";
import { convertUTCtoLocalDateTime } from "@/utils/helper";
import TableLine from "@/components/import/TableLine.vue";

const ImportRepository = RepositoryFactory.get("import");

export default {
  components: {
    TableLine,
  },
  props: {
    filter: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      table: this.tableDefaultState(),
      activeModalDetail: false,
      activeParentID: 0,
    };
  },
  methods: {
    closeModal() {
      this.activeModalDetail = false;
    },
    openModalDetail(data) {
      this.activeModalDetail = true;
      console.log("data", data);
      this.activeParentID = data.id;
      this.$nextTick(() => {
        this.$refs.tableLine.getData();
      });
    },
    convertUTCtoLocalDateTime,
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    async getData() {
      this.$vs.loading();
      try {
        const params = {
          params: {
            page: this.table.page,
            length: this.table.length,
            order: this.table.order,
            sort: this.table.sort,
            search: this.table.search,

            ...this.filter,
          },
        };

        const resp = await ImportRepository.getList(params);
        if (resp.code == 200) {
          this.table.total = resp.data.total_record;
          this.table.totalPage = resp.data.total_page;
          this.table.totalSearch = resp.data.total_record_search;
          this.table.length = resp.data.total_record_per_page;
          this.table.data = resp.data.records;
          this.setStartEnd();
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
      this.$vs.loading.close();
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    setFilter(data) {
      this.table.page = 1;
      this.filter = data;
      this.getData();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.table.page = val;
        this.handleChangePage(val);
      },
    },
  },
};
</script>
