<template>
  <div>
    <multiselect
      v-model="selected"
      :options="options"
      placeholder="Type to search"
      track-by="name"
      label="name"
      :max-height="125"
      :allow-empty="allowEmpty"
      :multiple="isMultiple"
      :disabled="isDisable"
      :select-label="''"
      deselect-label=""
      :searchable="isSearchable"
    >
      <span slot="noResult">Oops! No data found</span>
      <template slot="singleLabel" slot-scope="props">
        <span class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </span>
      </template>

      <template slot="option" slot-scope="props">
        <div class="option__desc">
          <span class="option__title">{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>
<script>
import { RepositoryFactory } from "@/repositories/repositoryFactory";

const MasterRepository = RepositoryFactory.get("master");

export default {
  props: {
    isDisable: {
      type: Boolean,
      default: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    allowEmpty: {
      type: Boolean,
      default: true,
    },
    showAllLabel: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: true,
    },
    params:null,
  },
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  methods: {
    async setSelectedData(data) {
      this.selected = data;
    },
    async getData(params) {
      try {
        const resp = await MasterRepository.getDistributionChannels(params);
        if (resp.code == 200) {
          var options = [];
          if (this.showAllLabel && this.selected == null) {
            options.push({
              id: 0,
              name: "All",
            });

            this.selected = options[0];
          }
          resp.data.forEach(function (item) {
            options.push(item);
          }, options);
          this.options = options;

          console.log("this.params", this.params)
          if (this.params != null && this.params != undefined && this.params.params.selected) {
            this.selected = [];
            console.log("this.params.params.selected", this.params.params.selected)
            this.params.params.selected.forEach(dcId => {
              options.forEach(optDc => {
                if (optDc.ID == dcId) {
                  this.selected.push(optDc);
                }
              });
            });
          }
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "Error while fetching distribution channel data",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle",
        });
        console.log(error);
      }
    },
  },
  watch: {
    selected(v) {
      // check v is array or not
      if (Array.isArray(v)) {
        if (v.length > 1) {
          // remove ID = 0 from selected
          for (var i = 0; i < v.length; i++) {
            if (v[i].id == 0) {
              v.splice(i, 1);
            }
          }
        }
      }
      this.selected = v;
      this.$emit("data", v);
    },
  },
  mounted() {
    let params = this.params != null ? this.params : null;
    console.log("params", params)
    this.getData(params);
  },
};
</script>
